import React from 'react';

import Layout from '../components/Layout';
import Header from '../components/Header';
import Footer from '../components/Footer';
import BuildRelationshipsSection from '../components/sections/BuildRelationshipsSection';
import TopBrandsSection from '../components/sections/TopBrandsSection';
import Container from '../components/Container';

const NotFoundRoute = () => (
  <Layout>
    <Header graphic={undefined}>Your Page was Not Found</Header>

    <section className="bg-white text-sp-dark">
      <Container className="my-24 ">
        <div className="prose prose-lg max-w-none prose-headings:font-normal prose-h2:text-4xl prose-h3:text-3xl">
          <p className="text-xl">
            The page you were searching for could not be found. You can try going <a href="/">Home</a>.<br />
            <br />
            Or visit <a href="/about">About</a> to learn more about Subscribe Pro.
          </p>
        </div>
      </Container>
    </section>

    <TopBrandsSection containerClassName="max-w-screen-lg" showOneLineOnly showCards={false} />

    <BuildRelationshipsSection />

    <Footer />
  </Layout>
);

export default NotFoundRoute;
